import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { https } from "../../service/apiService";

const initialState = {
  lstProductType: [],
  isLoading: false,

  createProductType: false,
  isLoadingCreateType: false,

  updateProductType: false,
  isLoadingUpdateType: false,

  deleteProductType: false,
  isLoadingDeleteType: false,
};

export const getAllProductType = createAsyncThunk(
  "producttype/getall",
  async (thunkAPI) => {
    try {
      const res = await https.get("/goods_types/all");
      return res.data;
    } catch (error) {
      message.error(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//create
export const createProductType = createAsyncThunk(
  "producttype/create",
  async (thunkAPI) => {
    try {
      const res = await https.post(`/goods_types`);
      return res.data;
    } catch (error) {
      message.error(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//update
export const updateProductType = createAsyncThunk(
  "producttype/update",
  async (input, thunkAPI) => {
    try {
      const res = await https.put(`/goods_types/${input.id}`, input.data);
      return res.data;
    } catch (error) {
      message.error(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//delete
export const deleteProductType = createAsyncThunk(
  "producttype/delete",
  async (id, thunkAPI) => {
    try {
      const res = await https.delete(`/goods_types/${id}`);
      return res.data;
    } catch (error) {
      message.error(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const productTypeSlice = createSlice({
  name: "producttype",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    return (
      builder
        .addCase(getAllProductType.pending, (state) => {
          return {
            ...state,
            isLoading: true,
          };
        })
        .addCase(getAllProductType.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            lstProductType: payload.data,
          };
        })
        .addCase(getAllProductType.rejected, (state) => {
          return {
            ...state,
            isLoading: false,
          };
        })

        //create
        .addCase(createProductType.pending, (state) => {
          return {
            ...state,
            isLoadingCreateType: true,
          };
        })
        .addCase(createProductType.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoadingCreateType: false,
            createProductType: payload.data,
          };
        })
        .addCase(createProductType.rejected, (state) => {
          return {
            ...state,
            isLoadingCreateType: false,
          };
        })

        //update
        .addCase(updateProductType.pending, (state) => {
          return {
            ...state,
            isLoadingUpdateType: true,
          };
        })
        .addCase(updateProductType.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoadingUpdateType: false,
            updateProductType: payload.data,
          };
        })
        .addCase(updateProductType.rejected, (state) => {
          return {
            ...state,
            isLoadingUpdateType: false,
          };
        })

        //delete
        .addCase(deleteProductType.pending, (state) => {
          return {
            ...state,
            isLoadingDeleteType: true,
          };
        })
        .addCase(deleteProductType.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoadingDeleteType: false,
            deleteProductType: payload.data,
          };
        })
        .addCase(deleteProductType.rejected, (state) => {
          return {
            ...state,
            isLoadingDeleteType: false,
          };
        })
    );
  },
});

export const productTypeSelector = (state) => state.producttype;

export default productTypeSlice.reducer;
