import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { https } from '../../service/apiService';

const initialState = {
  allProduct: [],
  detailProduct: {},
  isLoading: false,

  createProduct: false,
  isLoadingCreate: false,

  updateProduct: false,
  isLoadingUpdate: false,

  deleteProduct: false,
  isLoadingDelete: false,
};

// get all
export const getAllProduct = createAsyncThunk('product/getAll', async (thunkAPI) => {
  try {
    const res = await https.get('/products/all');

    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// get detail
export const getDetailProduct = createAsyncThunk('product/getDetail', async (id, thunkAPI) => {
  try {
    const res = await https.get(`/products/${id}`);
    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// create
export const createProduct = createAsyncThunk('product/create', async (data, thunkAPI) => {
  try {
    const res = await https.post(`/products`, data);
    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// update
export const updateProduct = createAsyncThunk('product/update', async (input, thunkAPI) => {
  try {
    const res = await https.put(`/products/${input.id}`, input.data);
    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// delete
export const deleteProduct = createAsyncThunk('product/delete', async (id, thunkAPI) => {
  try {
    const res = await https.delete(`/products/${id}`);
    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    return (
      builder
        //getAll
        .addCase(getAllProduct.pending, (state) => {
          return {
            ...state,
            isLoading: true,
          };
        })
        .addCase(getAllProduct.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            allProduct: payload.data,
          };
        })
        .addCase(getAllProduct.rejected, (state) => {
          return {
            ...state,
            isLoading: false,
          };
        })
        //getDetail
        .addCase(getDetailProduct.pending, (state) => {
          return {
            ...state,
            isLoading: true,
          };
        })
        .addCase(getDetailProduct.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            detailProduct: payload.data,
          };
        })
        .addCase(getDetailProduct.rejected, (state) => {
          return {
            ...state,
            isLoading: false,
          };
        })
        //create
        .addCase(createProduct.pending, (state) => {
          return {
            ...state,
            isLoadingCreate: true,
          };
        })
        .addCase(createProduct.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoadingCreate: false,
            detailProduct: payload.data,
          };
        })
        .addCase(createProduct.rejected, (state) => {
          return {
            ...state,
            isLoadingCreate: false,
          };
        })
        //update
        .addCase(updateProduct.pending, (state) => {
          return {
            ...state,
            isLoadingUpdate: true,
          };
        })
        .addCase(updateProduct.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoadingUpdate: false,
            detailProduct: payload.data,
          };
        })
        .addCase(updateProduct.rejected, (state) => {
          return {
            ...state,
            isLoadingUpdate: false,
          };
        })
        //delete
        .addCase(deleteProduct.pending, (state) => {
          return {
            ...state,
            isLoadingDelete: true,
          };
        })
        .addCase(deleteProduct.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoadingDelete: false,
            detailProduct: payload.data,
          };
        })
        .addCase(deleteProduct.rejected, (state) => {
          return {
            ...state,
            isLoadingDelete: false,
          };
        })
    );
  },
});
// Action creators are generated for each case reducer function
export const { reset } = productSlice.actions;
export const productSelector = (state) => state.product;

export default productSlice.reducer;
