import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { https } from "../../service/apiService";

const initialState = {
  currentUserInfo: {
    data: null,
    isLoading: false,
  },
  lstUser: [],
  isLoading: false,
  sendOTP: {
    data: false,
    isLoading: false,
  },
  createUser: {
    data: 0,
    isLoading: false,
  },
  updateUser: {
    data: 0,
    isLoading: false,
  },
  enableUser: {
    data: 0,
    isLoading: false,
  },
  disableUser: {
    data: 0,
    isLoading: false,
  },
};

export const getCurrentUserInfo = createAsyncThunk(
  "users/getCurrentUserInfo",
  async (thunkAPI) => {
    try {
      const res = await https.get("/users");
      return res.data;
    } catch (error) {
      message.error(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getLstUser = createAsyncThunk(
  "users/getLstUser",
  async (thunkAPI) => {
    try {
      const res = await https.get("/users/all");
      return res.data;
    } catch (error) {
      message.error(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (data, thunkAPI) => {
    try {
      const res = await https.post("/users/create", data);
      return res.data;
    } catch (error) {
      message.error(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const sendOTP = createAsyncThunk("users/sendotp", async (phone_number, thunkAPI) => {
  try {
    const res = await https.get(`/users/verify/${phone_number}`);
    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (thunkAPI) => {
    try {
      const res = await https.put("/users/create");
      return res.data;
    } catch (error) {
      message.error(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    return (
      builder
        //create user
        .addCase(createUser.pending, (state) => {
          return {
            ...state,
            isLoading: true,
          };
        })
        .addCase(createUser.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            data: payload.data,
          };
        })
        .addCase(createUser.rejected, (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
          };
        })
        //send otp
        .addCase(sendOTP.pending, (state) => {
          return {
            ...state,
            isLoading: true,
          };
        })
        .addCase(sendOTP.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            data: payload.data,
          };
        })
        .addCase(sendOTP.rejected, (state) => {
          return {
            ...state,
            isLoading: false,
          };
        })
        //get lst user
        .addCase(getLstUser.pending, (state) => {
          return {
            ...state,
            isLoading: true,
          };
        })
        .addCase(getLstUser.fulfilled, (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            lstUser: payload.data,
          };
        })
        .addCase(getLstUser.rejected, (state) => {
          return {
            ...state,
            isLoading: false,
          };
        })
    );
  },
});

export const userAction = userSlice.actions;
export const userSelector = (state) => state.user;

export default userSlice.reducer;
