import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

import { localStorageService } from "../../service/localStogeService";
import { https } from "../../service/apiService";

const initialState = {
  accessToken: null,
  isloading: false,
  isLoggedIn: !!localStorageService.get("USER"),
  registerSuccess: false,
  isRegisterAccountSuccess: false,
  inforUser: null,
};

//LOGIN
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (user, thunkAPI) => {
    try {
      const res = await https.post("/users/login", user);
      localStorageService.set("accessToken", res.data.data.token);
      localStorageService.set("USER", res.data.data);
      message.success("login success");
      return res.data;
    } catch (error) {
      message.error(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// LOGINOUT
export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (thunkAPI) => {
    try {
      await localStorageService.remove("USER");
      await localStorageService.remove("accessToken");
      return;
    } catch (error) {
      message.error("Login fail");
    }
  }
);

// export const registerUser = createAsyncThunk(
//   "auth/registerUser",
//   async (infor, thunkAPI) => {
//     try {
//       const res = await https.post("/api/auth/signup", infor);
//       message.success("Register success");
//       return res.data;
//     } catch (error) {
//       message.error("Login fail");
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );

//REGISTER

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    return builder
      .addCase(loginUser.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          accessToken: payload.token,
          isLoggedIn: !!payload,
          inforUser: payload.data,
        };
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          accessToken: payload.token,
          isLoggedIn: false,
        };
      })
      .addCase(logoutUser.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(logoutUser.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          isLoggedIn: false,
        };
      });
    // .addCase(registerUser.pending, (state) => {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // })
    // .addCase(registerUser.fulfilled, (state, { payload }) => {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     registerSuccess: true,
    //   };
    // })
    // .addCase(registerUser.rejected, (state, { payload }) => {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     registerSuccess: false,
    //     isRegisterAccountSuccess: true,
    //   };
    // });
  },
});
// Action creators are generated for each case reducer function
export const { reset } = authSlice.actions;

export default authSlice.reducer;
