import { Avatar, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLstUser, userSelector } from "../../redux/user/userSlice";

function UserPage() {
  // const [form] = Form.useForm();
  const dispatch = useDispatch();
  const selector = useSelector(userSelector);

  const columns = [
    {
      title: "",
      dataIndex: "avatar",
      key: "avatar",
      render: (_text, record) => {
        return <Avatar src={<img src={record.avatar} alt="avatar" />} />;
      },
    },
    {
      title: "Họ và tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ngày sinh",
      dataIndex: "birthday",
      key: "birthday",
      render: (_text, record) => {
        return moment(record.birthday).format("DD/MM/YYYY");
      },
    },
    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      align: "center",
      render: (_text, record) => {
        return (
          <>
            {record.gender === 1 && <Tag color="blue">Nam</Tag>}
            {record.gender === 0 && <Tag color="pink">Nữ</Tag>}
            {record.gender === -1 && <Tag color="pink">Khác</Tag>}
          </>
        );
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      key: "active",
      render: (value) => {
        return (
          <>
            {value && <Tag color="success">Is active</Tag>}
            {!value && <Tag color="danger">Not active</Tag>}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (selector.lstUser.length <= 0) {
      dispatch(getLstUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Table
        loading={selector.isLoading}
        columns={columns}
        dataSource={selector.lstUser}
        pagination={false}
        scroll={{ x: 720 }}
      />
    </React.Fragment>
  );
}

export default UserPage;
