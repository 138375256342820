import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { https } from '../../service/apiService';

const initialState = {
  lstPaymentMethod: [],
  isLoading: false,
};

export const getLstPaymentMethod = createAsyncThunk('payment_methods/getLst', async (thunkAPI) => {
  try {
    const res = await https.get('/payment_methods/all');
    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const paymentMethodSlice = createSlice({
  name: 'payment_methods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    return builder
      .addCase(getLstPaymentMethod.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getLstPaymentMethod.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          lstPaymentMethod: payload.data,
        };
      })
      .addCase(getLstPaymentMethod.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
        };
      });
  },
});

export const paymentMethodAction = paymentMethodSlice.actions;
export const paymentMethodSelector = (state) => state.paymentMethod;

export default paymentMethodSlice.reducer;
