import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { https } from '../../service/apiService';

const initialState = {
  allItemInCart: [],
  isLoading: false,
};

export const getAllItemInCart = createAsyncThunk('cart/getAll', async (thunkAPI) => {
  try {
    const res = await https.get('/carts/all');
    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const addToCart = createAsyncThunk('cart/addToCart', async (params, thunkAPI) => {
  try {
    const res = await https.post('/carts/', params);

    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// cancle order
export const cancleOrder = createAsyncThunk('cart/addToCart', async (params, thunkAPI) => {
  try {
    const res = await https.delete(`/orders/${params}/cancel`);
    console.log(res);
    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    return builder
      .addCase(getAllItemInCart.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getAllItemInCart.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          allItemInCart: payload.data,
        };
      })
      .addCase(getAllItemInCart.rejected, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
        };
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset } = cartSlice.actions;

export default cartSlice.reducer;
