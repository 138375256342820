import React, { useEffect, useLayoutEffect, useState } from 'react';
import './HomePage.scss';
import CardItem from '../../component/CardItem/CardItem';
import BannerCarousel from '../../component/BannerCarousel/BannerCarousel';
import IntroductionTree from '../../component/Introduction/IntroductTree';
import FilterHomePage from './Component/Filter/Filter';
import LiveAnyway from './Component/LiveAnyway/LiveAnyway';
import ServiceSection from './Component/ServiceSection/ServiceSection';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProduct, getDetailProduct } from '../../redux/produdct/productSlice';
import { Input, Modal, Button, InputNumber } from 'antd';
import 'animate.css';
import wow from 'wowjs';
import Skeletons from '../../component/Skeletons/Skeleton';
import { addToCart, getAllItemInCart } from '../../redux/cart/cartSlice';

function HomePage() {
    const dispatch = useDispatch();
    const getAllProductRedux = useSelector((state) => state.product.allProduct);
    const isLoading = useSelector((state) => state.product.isLoading);
    const [allProduct, setAllProduct] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [count, setCount] = useState(1);
    const [detailItemInfor, setDetailItemInfor] = useState([]);
    const handleOk = async () => {
        setIsModalOpen(false);
        const itemInfor = {
            product_id: detailItemInfor._id,
            amount: count,
        };
        await dispatch(addToCart(itemInfor));
        dispatch(getAllItemInCart());
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleGetItemInfor = (item) => {
        setDetailItemInfor(item);
    };

    useLayoutEffect(() => {
        dispatch(getAllProduct());
    }, []);

    const handleChangecCount = (value) => {
        setCount(value);
    };

    useEffect(() => {
        new wow.WOW({
            live: false,
        }).init();
    }, []);
    useEffect(() => {
        setAllProduct(getAllProductRedux);
    }, [getAllProductRedux]);

    const handleMapProduct = () => {
        return allProduct.map((items, index) => {
            return (
                <CardItem
                    setIsModalOpen={setIsModalOpen}
                    handleGetItemInfor={handleGetItemInfor}
                    id={items._id}
                    key={index}
                    inforProduct={items}
                />
            );
        });
    };

    const { Search } = Input;
    const onSearch = (value) => console.log(value);

    return (
        <div className="homepage_container">
            <BannerCarousel />
            <div className="relative mx-auto px-10 py-20 .from-black-revert">
                <LiveAnyway />
                <div className="background absolute w-full h-2/4 bg-[black] z-[5] top-0 right-0"> </div>
            </div>
            <div className="p-6">
                <div className="w-full flex justify-between">
                    <h1 className="text-[#7FA25C] font-bold text-[1.5rem] mb-5"> Sản phậm được ưa chuộng nhất </h1>
                </div>
                <FilterHomePage />
                {isLoading ? (
                    <Skeletons />
                ) : (
                    <div className="grid mb:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {handleMapProduct()}
                    </div>
                )}
            </div>
            <ServiceSection />
            <IntroductionTree />
            {/* <Modal

            
                title={detailItemInfor.name}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Add to cart
                    </Button>,
                ]}
            >
                <div className="flex mb:flex-col">
                    <div className="pr-1 overflow-hidden ">
                        <img
                            className="rounded-md"
                            // src={`${detailItemInfor}`}

                            src={
                                detailItemInfor === []
                                    ? 'https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg'
                                    : `data:image/jpeg;base64,${detailItemInfor?.images[0]}`
                            }
                            alt=""
                        />
                    </div>
                    <div className="pl-1">
                        <h1 className="text-[17px] text-[#7FA25C] font-bold"> Descript </h1>
                        <p className="text-[13px]">{detailItemInfor.description}</p>
                        <div className="mt-1 flex items-center">
                            <InputNumber min={1} max={10} defaultValue={1} count={handleChangecCount} />
                            <p className="text-[14px] text-[#fe3d3d] ml-2 font-bold">{detailItemInfor.price}$</p>
                        </div>
                    </div>
                </div>
            </Modal> */}
        </div>
    );
}

export default HomePage;
