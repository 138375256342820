import {
  BankOutlined, CodeSandboxOutlined,
  ContactsOutlined, FileDoneOutlined, UnorderedListOutlined
} from "@ant-design/icons";
import { Divider, Layout, Menu } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { Link, Outlet } from "react-router-dom";

const { Header, Content, Sider } = Layout;

function ManagerLayout() {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const menu = [
    getItem(
      <Link to={"/manager"}>Homepage Management</Link>,
      "Homepage",
      <BankOutlined />,
      null,
      null
    ),
    getItem(
      <Link to={"/manager/user"}>User Management</Link>,
      "User",
      <ContactsOutlined />,
      null,
      null
    ),
    getItem(
      <Link to={"/manager/product"}>Product Management</Link>,
      "Product",
      <CodeSandboxOutlined />,
      null,
      null
    ),
    getItem(
      <Link to={"/manager/product-type"}>Product Type Management</Link>,
      "ProductType",
      <UnorderedListOutlined />,
      null,
      null
    ),
    getItem(
      <Link to={"/manager/order"}>Order Management</Link>,
      "Order",
      <FileDoneOutlined />,
      null,
      null
    ),
  ];

  return (
    <Layout>
      <Header style={{ height: "50px", backgroundColor:'darkgreen' }} className="header">
        <div className="logo" />
      </Header>
      <Layout>
        <Sider
          width={300}
          style={{
            background: "#fff",
          }}
        >
          <Title className="mt-5 ml-7" level={2}>Tree shop</Title>

          <Divider />

          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{
              height: "100%",
              borderRight: 0,
            }}
            items={menu}
          />
        </Sider>
        <Layout>
          <Content className="min-h-screen m-5">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default ManagerLayout;
