function PageNotFound() {
  return (
    <div className="w-full">
      <section class="page_404">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 ">
              <div class="col-sm-10 col-sm-offset-1  text-center">
                <div class="four_zero_four_bg">
                  <h1 class="text-center ">404</h1>
                </div>

                <div class="contant_box_404">
                  <h3 class="h2">Không tìm thấy trang</h3>

                  <p>{"Trang bạn đang tìm kiếm không tồn tại :<<<"}</p>

                  <a href="/" class="link_404">
                    Trang chủ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default PageNotFound;
