import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, notification } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductType,
  productTypeSelector,
  updateProductType,
} from "../../../redux/productType/productTypeSlice";

function EditComp(props) {
  const { record } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const selector = useSelector(productTypeSelector);

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };
  const handleEdit = () => {
    form.setFieldsValue({
      name: record.name,
    });
    setOpen(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleFinish = async (e) => {
    const input = {
      id: record._id,
      data: {
        name: e.name || record.name,
      },
    };

    dispatch(updateProductType(input))
      .unwrap()
      .then((res) => {
        if (res.data) {
          notification.success({
            description: res.message || "Successfully updated a product type",
          });
          dispatch(getAllProductType());
          handleCancel();
        }
        if (!res) {
          notification.destroy({ description: res.error || "Something wrong" });
        }
      });
  };

  return (
    <React.Fragment>
      <Button
        loading={selector.isLoadingUpdateType}
        className="btn-primary"
        size="small"
        icon={<EditOutlined />}
        block
        onClick={handleEdit}
      >
        Edit
      </Button>

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        open={open}
        title="Edit Product Type"
        footer={
          <React.Fragment>
            <Button
              loading={selector.isLoadingUpdateType}
              onClick={handleCancel}
              className="btn-danger"
            >
              Cancel
            </Button>
            <Button
              loading={selector.isLoadingUpdateType}
              onClick={handleOk}
              className="btn-primary"
            >
              Confirm
            </Button>
          </React.Fragment>
        }
      >
        <Divider className="mt-0" />
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Form.Item name="name" label="Product Type Name">
            <Input defaultValue={record.name} placeholder="Field product type name" />
          </Form.Item>
        </Form>
        <Divider />
      </Modal>
    </React.Fragment>
  );
}

export default EditComp;
