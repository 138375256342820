import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, notification, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductType,
  getAllProductType,
  productTypeSelector,
} from "../../redux/productType/productTypeSlice";
import DeleteComp from "./components/DeleteComp";
import EditComp from "./components/EditComp";

function ProductTypePage() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const selector = useSelector(productTypeSelector);
  const [open, setOpen] = useState(false);

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      render: (_text, _record, index) => {
        return index + 1;
      },
    },
    {
      title: "Loại hàng",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      width: 200,
      render: (_, record) => (
        <Space direction="vertical">
          <EditComp record={record} />

          <DeleteComp id={record._id} />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (selector.lstProductType.length <= 0) {
      dispatch(getAllProductType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };
  const handleAdd = () => {
    setOpen(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleFinish = async (e) => {
    const data = {
      name: e.name,
    };

    dispatch(createProductType(data))
      .unwrap()
      .then((res) => {
        if (res.data) {
          notification.success({
            description: res.message || "Successfully created a product type",
          });
          dispatch(getAllProductType());
          handleCancel();
        }
        if (!res) {
          notification.destroy({ description: res.error || "Something wrong" });
        }
      });
  };

  return (
    <React.Fragment>
      <Space className="w-full flex justify-end">
        <Button
          loading={selector.isLoadingCreateType}
          onClick={handleAdd}
          className="btn-success mb-[10px]"
          icon={<PlusCircleOutlined />}
        >
          Create
        </Button>
      </Space>

      <Table
        loading={selector.isLoading}
        columns={columns}
        dataSource={selector.lstProductType}
        pagination={false}
        scroll={{ x: 720 }}
      />

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        open={open}
        title="Create Product Type"
        footer={
          <React.Fragment>
            <Button
              loading={selector.isLoadingUpdateType}
              onClick={handleCancel}
              className="btn-danger"
            >
              Cancel
            </Button>
            <Button
              loading={selector.isLoadingUpdateType}
              onClick={handleOk}
              className="btn-primary"
            >
              Confirm
            </Button>
          </React.Fragment>
        }
      >
        <Divider className="mt-0" />
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Form.Item name="name" label="Product Type Name">
            <Input placeholder="Field product type name" />
          </Form.Item>
        </Form>
        <Divider />
      </Modal>
    </React.Fragment>
  );
}

export default ProductTypePage;
