import { LoginOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
} from "antd";
import Title from "antd/es/typography/Title";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardEmpty from "../../component/common/CardEmpty";
import { createUser, sendOTP, userSelector } from "../../redux/user/userSlice";

const { Option } = Select;

function RegisterPage() {
  const [form] = Form.useForm();
  const [formOTP] = Form.useForm();
  const dispatch = useDispatch();
  const selector = useSelector(userSelector);
  const requesting = false;

  const [params, setParams] = useState();
  const [isAgree, setIsAgree] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);

  const handleCancelModal = () => {
    setShowOTPInput(false);
  };
  const handleConfirmModal = () => {
    formOTP.submit();
  };
  const handleFinishOTP = async (e) => {
    dispatch(createUser({ ...params, code: e.otp }));
  };

  const handleRegis = () => {
    form.submit();
  };
  const handleFinish = async (e) => {
    if (e.passwordrepeat === e.password) {
      const paramsRegis = {
        phone_number: e.phonenumber,
        name: e.fullname,
        birthday: moment(e.birthday).format("DD/MM/YYYY"),
        gender: e.gender,
        password: e.password,
      };
      setParams(paramsRegis);

      dispatch(sendOTP(e.phonenumber));
      console.log(selector.sendOTP.data)

      if (selector.sendOTP.data) {
        setShowOTPInput(true);
      }
    } else {
      notification.warning({
        description: "Mật khẩu nhập lại không trùng khớp!",
      });
      form.resetFields(["password", "passwordrepeat"]);
    }
  };

  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="py-10">
        <CardEmpty
          className="min-w-[500px]"
          isRadius={true}
          isShadow={true}
          children={
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFinish}
              className="form-row-gap-0"
            >
              <Title className="flex justify-center" level={2}>
                Đăng ký
              </Title>

              <Divider className="mt-0" />

              <Form.Item
                label="Số điện thoại"
                name="phonenumber"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số điện thoại!",
                  },
                ]}
                className="w-full"
              >
                <Input placeholder="Số điện thoại ..." />
              </Form.Item>

              <Form.Item
                label="Họ và tên"
                name="fullname"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập họ và tên!",
                  },
                ]}
                className="w-full"
              >
                <Input placeholder="Họ và tên ..." />
              </Form.Item>

              <Form.Item
                label="Ngày sinh"
                name="birthdate"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn ngày sinh!",
                  },
                ]}
                className="w-full"
              >
                <DatePicker
                  placeholder="Chọn ngày sinh"
                  picker="date"
                  format="DD/MM/YYYY"
                  className="w-full"
                />
              </Form.Item>

              <Form.Item
                label="Giới tính"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập giới tính!",
                  },
                ]}
                className="w-full"
              >
                <Select placeholder="Chọn giới tính">
                  <Option key={1} value={1}>
                    Nam
                  </Option>
                  <Option key={0} value={0}>
                    Nữ
                  </Option>
                  <Option key={-1} value={-1}>
                    Khác
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Mật khẩu"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mật khẩu!",
                  },
                ]}
                className="w-full"
              >
                <Input.Password placeholder="Nhập mật khẩu ..." />
              </Form.Item>

              <Form.Item
                label="Nhập lại mật khẩu"
                name="passwordrepeat"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập lại mật khẩu!",
                  },
                ]}
                className="w-full"
              >
                <Input.Password placeholder="Nhập lại mật khẩu ..." />
              </Form.Item>

              <Form.Item name="isagree" className="w-full">
                <Checkbox
                  onChange={() => {
                    setIsAgree(!isAgree);
                  }}
                >
                  Tôi đồng ý chính sách bảo mật
                </Checkbox>
              </Form.Item>

              <Divider className="mt-0" />

              <Form.Item className="justify-center">
                <Button
                  className="btn-success"
                  icon={<LoginOutlined />}
                  onClick={handleRegis}
                  loading={requesting}
                  block
                >
                  Đăng ký
                </Button>
              </Form.Item>
            </Form>
          }
        />
      </div>

      <Modal
        title="Xác nhận OTP"
        visible={showOTPInput}
        centered
        onOk={handleConfirmModal}
        onCancel={handleCancelModal}
        footer={[
          <Button key="cancel" onClick={handleCancelModal}>
            Quay lại
          </Button>,

          <Button
            key="yes"
            className="btn-success"
            onClick={handleConfirmModal}
          >
            Xác nhận
          </Button>,
        ]}
      >
        <Divider className="mb-0" />
        <Form
          form={formOTP}
          layout="vertical"
          onFinish={handleFinishOTP}
          className="form-row-gap-0"
        >
          <Form.Item
            label="Nhập mã OTP"
            name="otp"
            rules={[
              {
                required: true,
                message:
                  "Vui lòng nhập mã OTP được gửi vào số điện thoại của bạn!",
              },
            ]}
            className="w-full"
          >
            <Input placeholder="Nhập mã OTP ..." />
          </Form.Item>
        </Form>
        <Divider />
      </Modal>
    </div>
  );
}

export default RegisterPage;
