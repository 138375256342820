import authSlice from './auth/authSlice';
import { combineReducers } from 'redux';
import productSlice from './produdct/productSlice';
import userSlice from './user/userSlice';
import cartSlice from './cart/cartSlice';
import productTypeSlice from './productType/productTypeSlice';
import orderSlice from './Order/orderSlice';
import paymentMethodSlice from './paymentMethod/paymentMethodSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  product: productSlice,
  cart: cartSlice,
  user: userSlice,
  producttype: productTypeSlice,
  order: orderSlice,
  paymentMethod: paymentMethodSlice,
});
export default rootReducer;
