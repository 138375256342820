import { Badge, Button, Popover } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './UserNav.scss';
// import { loginAction } from "../../Redux/Actions/userAction";
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../redux/auth/authSlice';
import ItemCart from './Component/ItemCart/ItemCart';
import { localStorageService } from '../../service/localStogeService';
import { getAllItemInCart } from '../../redux/cart/cartSlice';
import { https } from '../../service/apiService';
export default function UserNav() {
    let dispatch = useDispatch();
    let history = useNavigate();
    const allCart = useSelector((state) => state.product.detailProduct);
    const [openUserInfo, setOpenUserInfo] = useState(false);
    const inforAuth = localStorageService.get('USER');
    const [first, setfirst] = useState(5);
    const auth = useSelector((state) => state.auth.isLoggedIn);
    const getAllItemInCarts = useSelector((state) => state.cart.allItemInCart);
    const [allItemInCart, setAllItemInCart] = useState([]);
    const navigate = useNavigate();
    const handleLogOut = async () => {
        await dispatch(logoutUser());
        history('/login');
    };
    const [count, setCount] = useState(0);

    useEffect(() => {
        dispatch(getAllItemInCart());
    }, []);

    useEffect(() => {
        setAllItemInCart(getAllItemInCarts);

        setCount(getAllItemInCarts.length);
    }, [getAllItemInCarts]);
    // console.log("allItemInCart",allItemInCart);
    // const handleMapCart = (param) => {
    //     return param?.map((items, index) => {
    //         return <ItemCart key={index} infor={items} />;
    //     });
    // };
    // const cartContent = <div className="">{handleMapCart(allItemInCart)}</div>;
    const contentProfile = (
        <div className="flex flex-col">
            <button
                onClick={() => {
                    navigate('./profile');
                }}
                className="bg-[white] border-none p-2 hover:bg-[#dfdfdf] transition-all cursor-pointer"
            >
                <div className="flex items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 mr-2"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                    </svg>

                    <p>Xem trang cá nhân</p>
                </div>
            </button>
            <button
                className="bg-[white] border-none p-2 hover:bg-[#dfdfdf] transition-all cursor-pointer"
                onClick={() => {
                    handleLogOut();
                }}
            >
                <div className="flex items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 mr-2"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                        />
                    </svg>

                    <p>Đăng xuất</p>
                </div>
            </button>
        </div>
    );

    const checkLogin = () => {
        if (auth) {
            return (
                <Popover placement="bottomRight" className="popover-cart" content={contentProfile}>
                    <div className="flex items-center bg-[transparent] hover:bg-[#7FA25C] transition-all p-2 hover:text-[white] rounded-[0.2rem] justify-center cursor-pointer">
                        <div
                            className="rounded-[2rem] h-[32px] w-[32px] overflow-hidden lg:mr-2 "
                            style={{ border: '1px solid #7FA25C' }}
                        >
                            <img
                                className=""
                                src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-social-media-user-vector-image-icon-default-avatar-profile-icon-social-media-user-vector-image-209162840.jpg"
                                alt=""
                            />
                        </div>
                        <h1 className="mb:hidden sm:hidden lg:block">{inforAuth.name}</h1>
                    </div>
                </Popover>
            );
        } else {
            return (
                <Button
                    shape="round"
                    className="loginBTN"
                    type="primary"
                    onClick={() => {
                        history('/login');
                    }}
                >
                    Đăng nhập
                </Button>
            );
        }
    };
    const renderContent = () => {
        return (
            <div className="flex justify-end items-center lg:flex-row mb:items-center sm:items-center lg:items-center ">
                {/* <Popover
                    placement="bottomRight"
                    className={`${inforAuth ? 'block' : 'hidden'} popover-cart`}
                    // content={cartContent}
                    title="giỏ hàng:"
                    onClick={() => {
                        navigate('./cart');
                    }}
                > */}
                <button
                    className="cart mr-2"
                    onClick={() => {
                        navigate('./cart');
                    }}
                >
                    <Badge count={count}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                />
                            </svg>
                        </div>
                    </Badge>
                </button>
                {/* </Popover> */}

                {checkLogin()}
            </div>
        );
    };
    return <div>{renderContent()}</div>;
}
