import React from "react";
import { Divider, Form, Radio, Skeleton, Space, Switch } from "antd";
import "./Skeleton.scss";
const Skeletons = () => {
  return (
    <div className="grid mb:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      <div className="card-item  animate__fadeInUp animate__animated wow p-3 relative rounded-[0.5rem] bg-[transparent] transition-all hover:bg-slate-200">
        <div className=" rounded-[0.5rem] ">
          <Skeleton.Image
            active={true}
            className=""
            style={{ width: "100%" }}
          />
          <div className="my-2">
            <div className="flex flex-col items-center w-full">
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input active={true} />
            </div>
          </div>
        </div>
      </div>
      <div className="card-item  animate__fadeInUp animate__animated wow p-3 relative rounded-[0.5rem] bg-[transparent] transition-all hover:bg-slate-200">
        <div className=" rounded-[0.5rem] ">
          <Skeleton.Image
            active={true}
            className=""
            style={{ width: "100%" }}
          />
          <div className="my-2">
            <div className="flex flex-col items-center w-full">
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input active={true} />
            </div>
          </div>
        </div>
      </div>
      <div className="card-item  animate__fadeInUp animate__animated wow p-3 relative rounded-[0.5rem] bg-[transparent] transition-all hover:bg-slate-200">
        <div className=" rounded-[0.5rem] ">
          <Skeleton.Image
            active={true}
            className=""
            style={{ width: "100%" }}
          />
          <div className="my-2">
            <div className="flex flex-col items-center w-full">
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input active={true} />
            </div>
          </div>
        </div>
      </div>
      <div className="card-item  animate__fadeInUp animate__animated wow p-3 relative rounded-[0.5rem] bg-[transparent] transition-all hover:bg-slate-200">
        <div className=" rounded-[0.5rem] ">
          <Skeleton.Image
            active={true}
            className=""
            style={{ width: "100%" }}
          />
          <div className="my-2">
            <div className="flex flex-col items-center w-full">
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input active={true} />
            </div>
          </div>
        </div>
      </div>
      <div className="card-item  animate__fadeInUp animate__animated wow p-3 relative rounded-[0.5rem] bg-[transparent] transition-all hover:bg-slate-200">
        <div className=" rounded-[0.5rem] ">
          <Skeleton.Image
            active={true}
            className=""
            style={{ width: "100%" }}
          />
          <div className="my-2">
            <div className="flex flex-col items-center w-full">
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input active={true} />
            </div>
          </div>
        </div>
      </div>
      <div className="card-item  animate__fadeInUp animate__animated wow p-3 relative rounded-[0.5rem] bg-[transparent] transition-all hover:bg-slate-200">
        <div className=" rounded-[0.5rem] ">
          <Skeleton.Image
            active={true}
            className=""
            style={{ width: "100%" }}
          />
          <div className="my-2">
            <div className="flex flex-col items-center w-full">
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input
                active={true}
                block={false}
                className="w-full mb-1"
                style={{ width: "100%" }}
              />
              <Skeleton.Input active={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeletons;
