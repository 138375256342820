import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { https } from '../../service/apiService';

const initialState = {
  lstOrder: [],
  isLoading: false,

  lstOrderStatus: [],
  isLoadingStatus: false,
};

export const getLstOrder = createAsyncThunk('order/getLst', async (thunkAPI) => {
  try {
    const res = await https.get('/orders/all');
    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getLstOrderStatus = createAsyncThunk('order/status/getLst', async (thunkAPI) => {
  try {
    const res = await https.get('/orders/statuses/all');
    return res.data;
  } catch (error) {
    message.error(error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    return builder
      .addCase(getLstOrder.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getLstOrder.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoading: false,
          lstOrder: payload.data,
        };
      })
      .addCase(getLstOrder.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(getLstOrderStatus.pending, (state) => {
        return {
          ...state,
          isLoadingStatus: true,
        };
      })
      .addCase(getLstOrderStatus.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoadingStatus: false,
          lstOrderStatus: payload.data,
        };
      })
      .addCase(getLstOrderStatus.rejected, (state) => {
        return {
          ...state,
          isLoadingStatus: false,
        };
      });
  },
});

export const orderAction = orderSlice.actions;
export const orderSelector = (state) => state.order;

export default orderSlice.reducer;
