import { DeleteOutlined } from "@ant-design/icons";
import { Popover, Space, Button, notification } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  getAllProduct,
  productSelector,
} from "../../../redux/produdct/productSlice";

function DeleteComp(props) {
  const { id } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const selector = useSelector(productSelector);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleOnClick = () => {
    dispatch(deleteProduct(id))
      .unwrap()
      .then((res) => {
        if (res) {
          notification.success({
            description: res.message || "Successfully deleted a product",
          });
          dispatch(getAllProduct());
        }
        if (!res) {
          notification.destroy({ description: res.error || "Something wrong" });
        }
      });
    setOpen(false);
  };

  return (
    <Popover
      placement="topRight"
      content={
        <>
          Xoá sản phẩm này khỏi danh sách?
          <Space className="flex justify-end mt-1">
            <Button
              loading={selector.isLoadingDelete}
              className="btn-primary"
              size="small"
              onClick={handleOnClick}
            >
              Xác nhận
            </Button>
          </Space>
        </>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button
        loading={selector.isLoadingDelete}
        className="btn-danger"
        size="small"
        icon={<DeleteOutlined />}
        block
      >
        Xoá
      </Button>
    </Popover>
  );
}

export default DeleteComp;
