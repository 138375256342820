import { Space, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLstOrder, getLstOrderStatus, orderSelector } from '../../redux/Order/orderSlice';
import { getLstPaymentMethod, paymentMethodSelector } from '../../redux/paymentMethod/paymentMethodSlice';

function OrderPage() {
  const dispatch = useDispatch();
  const selector = useSelector(orderSelector);
  const selectorPaymentMethod = useSelector(paymentMethodSelector);

  const tagColor = [
    {
      id: '0',
      color: 'blue',
    },
    {
      id: '1',
      color: 'yellow',
    },
    {
      id: '2',
      color: 'orange',
    },
    {
      id: '3',
      color: 'green',
    },
    {
      id: '4',
      color: 'red',
    },
    {
      id: '5',
      color: 'violet',
    },
  ];

  const getStatusName = (status) => {
    const result = selector.lstOrderStatus.filter((item) => item.code === status);

    return result[0].name || '';
  };
  const getTagColor = (status) => {
    const result = tagColor.filter((item) => item.id === status);

    return result[0].color || '';
  };

  const getPaymentMethodName = (id) => {
    const result = selectorPaymentMethod.lstPaymentMethod.filter((item) => item._id === id);

    return result[0].name || '';
  };

  const columns = [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Creared At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => {
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Payment Method',
      dataIndex: 'payment_method_id',
      key: 'payment_method_id',
      render: (value) => {
        return getPaymentMethodName(value);
      },
    },
    {
      title: 'Status',
      dataIndex: 'order_status_code',
      key: 'order_status_code',
      render: (value) => {
        return <Tag color={getTagColor(value)}>{getStatusName(value)}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => <Space>aaaaaaaa</Space>,
    },
  ];

  useEffect(() => {
    dispatch(getLstOrderStatus());
    dispatch(getLstPaymentMethod());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selector.lstOrderStatus.length > 0 && selectorPaymentMethod.lstPaymentMethod.length > 0) {
      dispatch(getLstOrder());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector.lstOrderStatus, selectorPaymentMethod.lstPaymentMethod]);

  return (
    <React.Fragment>
      <Table
        loading={selector.isLoading}
        columns={columns}
        dataSource={selector.lstOrder}
        pagination={false}
        scroll={{ x: 720 }}
      />
    </React.Fragment>
  );
}

export default OrderPage;
