import { https } from './apiService';

export let productService = {
  getProductDetail: (id) => {
    return https.get(`/products/${id}`);
  },
  getAllProduct: () => {
    return https.get(`/product/getAll`);
  },
};
