import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Modal, Button, InputNumber } from 'antd';
import { getDetailProduct } from '../../redux/produdct/productSlice';
import { addToCart, getAllItemInCart } from '../../redux/cart/cartSlice';
import { productService } from '../../service/productService';
import './DetailProductPage.scss';
const DetailProductPage = () => {
  //   const detailProduct = useSelector((state) => state.product.detailProduct);
  const { productId } = useParams();
  const [image, setImage] = useState('');
  const [productDetail, setProductDetail] = useState({});
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();
  const handleChangecCount = (value) => {
    setCount(value);
  };
  useEffect(() => {
    productService
      .getProductDetail(productId)
      .then((res) => {
        setProductDetail(res.data.data);
        setImage(`data:image/jpeg;base64,${res.data.data.images[0]}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    dispatch(getDetailProduct(productId));
  }, []);
  const handleByTree = async () => {
    const itemInfor = {
      product_id: productId,
      amount: count,
    };
    await dispatch(addToCart(itemInfor));
    dispatch(getAllItemInCart());
  };

  return (
    <div className="mt-20 h-screen flex justify-center items-center">
      <div className="detail-content mb:block sm:block lg:flex gap-10 mx-10">
        <img
          src={`${image ? image : 'https://www.contentviewspro.com/wp-content/uploads/2017/07/default_image.png'}`}
          alt=""
          className="detail-img rounded-xl"
        />
        <div className="title p-5 mb:w-full lg:w-1/2">
          <h1 className="text-white font-bold text-xl uppercase">
            <span className="text-yellow-500">Tên Cây: </span> {productDetail.name}
          </h1>
          <h1 className="text-white my-3 font-bold">
            <span className="text-yellow-500">Mô tả: </span>
            {productDetail.description}
          </h1>
          <div className="rating  my-3">
            <h1 className="text-yellow-500 font-bold flex">
              Giá: {productDetail.price}$
              <span className="text-yellow-500 ml-2 font-bold text-sm flex items-center">
                {/* {movie.danhGia} <AiFillStar /> */}
              </span>{' '}
            </h1>
          </div>
          <div>
            <InputNumber min={1} max={10} defaultValue={3} onChange={handleChangecCount} />
          </div>
          <button
            onClick={handleByTree}
            className="w-full px-5 py-2 my-3 bg-red-600 rounded font-bold transition ease-in-out delay-15 hover:-translate-y-1 hover:scale-110 hover:bg-red-700 duration-300"
          >
            Mua ngay
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailProductPage;
