import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductType,
  productTypeSelector,
} from "../../../redux/productType/productTypeSlice";
import {
  getAllProduct,
  productSelector,
  updateProduct,
} from "../../../redux/produdct/productSlice";

const { Option } = Select;

function EditComp(props) {
  const { record } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const selector = useSelector(productSelector);
  const selectorType = useSelector(productTypeSelector);
  const lstType = selectorType.lstProductType;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open && selectorType.lstProductType.length <= 0) {
      dispatch(getAllProductType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };
  const handleEdit = () => {
    form.setFieldsValue({
      goods_type_id: record.goods_type_id,
      name: record.name,
      price: record.price,
      amount: record.amount,
      description: record.description,
    });
    setOpen(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleFinish = async (e) => {
    const inputImages = ["data:image/png;base64," + record.images];

    const input = {
      id: record._id,
      data: {
        goods_type_id: e.goods_type_id || record.goods_type_id,
        name: e.name || record.name,
        price: e.price || record.price,
        amount: e.amount || record.amount,
        description: e.description || record.description,
        images: inputImages,
      },
    };

    dispatch(updateProduct(input))
      .unwrap()
      .then((res) => {
        if (res.data) {
          notification.success({
            description: res.message || "Successfully updated a product",
          });
          dispatch(getAllProduct());
          handleCancel();
        }
        if (!res) {
          notification.destroy({ description: res.error || "Something wrong" });
        }
      });
  };

  return (
    <React.Fragment>
      <Button
        loading={selector.isLoadingDelete}
        className="btn-primary"
        size="small"
        icon={<EditOutlined />}
        block
        onClick={handleEdit}
      >
        Edit
      </Button>

      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        open={open}
        title="Edit Profile's Product"
        footer={
          <React.Fragment>
            <Button
              loading={selector.isLoadingUpdate}
              onClick={handleCancel}
              className="btn-danger"
            >
              Cancel
            </Button>
            <Button
              loading={selector.isLoadingUpdate}
              onClick={handleOk}
              className="btn-primary"
            >
              Confirm
            </Button>
          </React.Fragment>
        }
      >
        <Divider className="mt-0" />
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Form.Item name={"goods_type_id"} label="Loại sản phẩm">
            <Select
              name="goods_type_id"
              defaultValue={record.goods_type_id}
              placeholder="Chọn loại sản phẩm"
            >
              {lstType.map((item) => {
                return (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="name" label="Tên sản phẩm">
            <Input defaultValue={record.name} placeholder="Nhập tên sản phẩm" />
          </Form.Item>

          <Form.Item name="price" label="Đơn giá">
            <InputNumber
              className="w-full"
              min={0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              addonAfter={"VND"}
              placeholder="Nhập đơn giá"
              defaultValue={record.price}
            />
          </Form.Item>

          <Form.Item name="amount" label="Số lượng tồn">
            <InputNumber
              className="w-full"
              min={0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              placeholder="Nhập số lượng tồn"
              defaultValue={record.amount}
            />
          </Form.Item>

          <Form.Item name="description" label="Mô tả">
            <Input.TextArea
              rows={3}
              defaultValue={record.description}
              placeholder="Nhập mô tả"
            />
          </Form.Item>

          <Form.Item name="images" label="Hình ảnh">
            <Input placeholder="Đây là upload hình" />
          </Form.Item>
        </Form>
        <Divider />
      </Modal>
    </React.Fragment>
  );
}

export default EditComp;
