import axios from 'axios';
import { localStorageService } from './localStogeService';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
export const https = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorageService.get('accessToken')}`,
  },
  mode: 'cors',
  withCredentials: true,
});
// Add a request interceptorhome
https.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const corsOptions = {
  origin: '*',
  credentials: true, //access-control-allow-credentials:true
  optionSuccessStatus: 200,
};
// Add a response interceptor
https.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);
