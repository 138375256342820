export const PAGEURL = {
    HOMEPAGE: '/',
    CART: '/cart',
    LOGIN: '/login',
    REGIS: '/register',
    PROFILE: '/profile',
    DETAILPRODUCT: '/Detail-Product/:productId',

    MANAGER: {
        DEFAULT: '/manager',
        USER: '/manager/user',
        ORDER: '/manager/order',
        PRODUCT: '/manager/product',
        PRODUCTTYPE: '/manager/product-type',
    },

    PAYMENT: '/payment',
};
