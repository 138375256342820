import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { localStorageService } from "../../service/localStogeService";

function RequestAuth({ children }) {
  const auth = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth) navigate("/login");
  }, [auth, navigate]);
  return <>{children}</>;
}

export default RequestAuth;
